import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export function GetTweets({ numberOfTweets, screenName }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function loadData() {
            try {
                // twitter.php is placed in root of api.alteza.nl. This file is a proxy to talk with twitter api.
                // place screenname and number of Tweets in query to get the results back.
                const urlToFetch = `${process.env.REACT_APP_API_ENDPOINT || ''}../twitter.php?name=${screenName}&count=${numberOfTweets}`;
                const response = await fetch(urlToFetch);

                if (!response.ok) {
                    console.error('Error fetching Twitter data');
                    setData(undefined);
                    return;
                }

                const result = await response.json();
                if (result) {
                    if (result.error) {
                        console.error('Error fetching Twitter data:', result.error);
                    } else {
                        setData(result);
                    }
                }
            }
            catch (error) {
                console.error('Error fetching Twitter data ', error);
            }
        }

        try {
            loadData();
        } catch (error) {
            console.error('Error fetching Twitter data ', error);
        }
    }, [numberOfTweets, screenName]);
    return data;
};

GetTweets.propTypes = {
    numberOfTweets: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
};

export default GetTweets;
