import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import parse from 'html-react-parser';

export function ContentBlock(props) {
    const { title, content, size, alignment } = props;

    return (
        <div className={`content-block container content-block--${alignment || 'center'} content-block--${size || 'medium-width'}`}>
            <div className="content-block-column">
                <div className="content-block__content">
                    {title &&
                        <h2 className="content-block__content-title">
                            {title}
                        </h2>
                    }

                    {content &&
                        <div className="content-block__content-text">
                            <Markdown>
                                {parse(content)}
                            </Markdown>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

ContentBlock.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    size: PropTypes.string,
    alignment: PropTypes.string,
    history: PropTypes.object.isRequired,
};

export default withRouter(ContentBlock);
