const generic = (state = [], action) => {
    switch (action.type) {
        case 'SET_GENERIC_INFO':
            return action.genericInfo;
        default:
            return state;
    }
}

export default generic;
