import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigate } from '../../utils/common.js';
import Markdown from 'markdown-to-jsx';
import parse from 'html-react-parser';

export function ContentImageBlock(props) {
    const { inverseImageAlignment, image, title, content, buttons, size, history } = props;
    const alignment = (inverseImageAlignment === undefined) ? 'background' : (inverseImageAlignment === true ? 'left' : 'right');

    const { url: imageUrl } = image && image.id ? image : { url: image };

    const handleButtonClick = (event, route) => {
        event.preventDefault();
        const { target, url, slug } = route;
        navigate(history, { target, url, slug });
    }

    return (
        <React.Fragment>
            <div className={`content_image-block container content_image-block--${alignment} content_image-block--${size || 'medium-width'}`}>
                <div className="content_image-block-column">
                    <div className="content_image-block__content">
                        {title &&
                            <h2>
                                {title}
                            </h2>
                        }

                        {content &&
                            <div className="content_image-block__content-text">
                                <Markdown>
                                    {parse(content)}
                                </Markdown>
                            </div>
                        }

                        {buttons && buttons.map((mappedButton, index) => {
                            const button = mappedButton.button;
                            return (
                                <button
                                    href="#"
                                    key={index}
                                    className={`btn uppercase btn${index >= 1 ? '-secondary' : '-primary'}`}
                                    onClick={(event) => handleButtonClick(event, { url: button.url })}
                                >
                                    {button.title}
                                </button>
                            );
                        })}
                    </div>
                </div>

                {imageUrl &&
                    <div className="content_image-block-column content_image-block__image" style={{ backgroundImage: `${imageUrl ? `url(${imageUrl})` : ''}` }} />
                }
            </div>
        </React.Fragment>
    );
}

ContentImageBlock.propTypes = {
    inverseImageAlignment: PropTypes.bool,
    image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    title: PropTypes.string,
    content: PropTypes.string,
    buttons: PropTypes.array,
    size: PropTypes.string,
    history: PropTypes.object.isRequired,
};

export default withRouter(ContentImageBlock);
