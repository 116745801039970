import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { navigate } from '../../utils/common.js';
import parse from 'html-react-parser';

export function CardsBlock(props) {
    const { history, title, content, cards, variant } = props;
    const parsedCards = cards ? JSON.parse(parse(cards)) : [];

    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;
        if (url) {
            navigate(history, { target, url, slug });
        }
    }

    return (
        <div className="cards-block container">
            <div className="cards-block__content">
                {title &&
                    <h2>{title}</h2>
                }
                {content &&
                    <Markdown>
                        {parse(content)}
                    </Markdown>
                }
            </div>

            {cards &&
                <div className="cards-block__cards">
                    {parsedCards && parsedCards.map((card, index) => {
                        return (
                            <div className={`cards-block__cards-card ${variant ? variant : ''}`} key={index}>
                                <div className="cards-block__cards-card_image">
                                    {card.image &&
                                        <img src={card.image.url} alt={card.image.title} />
                                    }
                                </div>
                                {card.title &&
                                    <h3 onClick={(event) => handleButtonClick(event, { url: card.title_url })}>
                                        {card.title}
                                    </h3>
                                }
                                {card.content &&
                                    <div className="cards-block__cards-card_content">
                                        {parse(card.content)}
                                    </div>
                                }
                                {card.button &&
                                    <div className="cards-block__cards-card_actions">
                                        <button
                                            key={index}
                                            className="btn btn-primary uppercase cards-block__cards-card-button"
                                            onClick={(event) => handleButtonClick(event, { target: card.button.target, url: card.button.url })}
                                        >
                                            {card.button.title}
                                        </button>
                                    </div>
                                }
                            </div>
                        );
                    })
                    }
                </div>
            }
        </div >
    );
}

CardsBlock.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    cards: PropTypes.string,
    variant: PropTypes.string,
    history: PropTypes.object.isRequired,
};

export default withRouter(CardsBlock);
