import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Map from '../Map';
import { navigate } from '../../utils/common.js';
import ContactForm from '../ContactForm';

export function ContactBlock(props) {
    const { markerLocations, googleMapsProps, title, text, contactInfo, history, contactPageComponent } = props;
    // console.log(props);
    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;

        navigate(history, { target, url, slug });
    };

    const { lat, lng, zoom } = googleMapsProps || {};
    const markerProps = markerLocations || (lat && lng ? [{ lat: lat, lng: lng }] : undefined);

    return (
        <div className="contact-block container">
            <div className="contact-block__info">
                {markerProps &&
                    <div className="contact-block__map">
                        <Map
                            markers={markerProps} //, { lat: 52.268113, lng: 6.158973 }, { lat: 38.466963, lng: -3.324363 }
                            zoom={zoom || 16}
                        // size={{ width: '900px', height: '900px' }}
                        />
                    </div>
                }

                {contactInfo &&
                    <div className="contact-block__content">
                        {text &&
                            <div className="contact-block__content-text">
                                {text}
                            </div>
                        }
                        {title &&
                            <h3 className="contact-block__content-title">
                                {title}
                            </h3>
                        }
                        <div className="row">
                            <div className="left">
                                {contactInfo.street && contactInfo.number &&
                                    <span>
                                        {contactInfo.street} {contactInfo.number}
                                    </span>
                                }

                                {contactInfo.postalCode && contactInfo.city &&
                                    <span>
                                        {contactInfo.postalCode} {contactInfo.city}
                                    </span>
                                }

                                {contactInfo.country &&
                                    <span>
                                        {contactInfo.country}
                                    </span>
                                }
                            </div>
                            <div className="right">
                                {contactInfo.website &&
                                    <span>
                                        <button onClick={(event) => handleButtonClick(event, { url: `https://${contactInfo.website}` })}>{contactInfo.website}</button>
                                    </span>
                                }

                                {contactInfo.email &&
                                    <span>
                                        <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                                    </span>
                                }

                                {contactInfo.phone &&
                                    <span>
                                        <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="contact-block__form">
                <ContactForm
                    contactPageComponent={contactPageComponent}
                />
            </div>
        </div >
    );
}

ContactBlock.propTypes = {
    history: PropTypes.object.isRequired,
    markerLocations: PropTypes.array, // [{ lat: '', lng: '' }]
    googleMapsProps: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    contactInfo: PropTypes.object,
    contactPageComponent: PropTypes.node,
};

export default withRouter(ContactBlock);
