import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { navigate } from '../utils/common.js';
import UseFetch from '../hooks/useFetch';
import Markdown from 'markdown-to-jsx';
import 'font-awesome/css/font-awesome.min.css';

export function Footer(props) {
    const { history, socialIcons, backgroundImage, sloganImage } = props;
    const [menu] = UseFetch({ slug: 'menus/v1/menus/footer' });

    const handleButtonClick = (event, button) => {
        event.preventDefault();
        const { target, url } = button;

        navigate(history, { target, url });
    }

    return (
        <div className="footer" style={{ backgroundImage: `${backgroundImage ? `url(${backgroundImage})` : ''}` }}>
            <div className="footer__content">
                <div className="footer__social">
                    {socialIcons && socialIcons.map((icon, index) => {
                        return (
                            <Avatar
                                key={index}
                                onClick={(event) => handleButtonClick(event, { url: icon.url })}
                                className="footer__social-icon"
                            >
                                {/* workarround to override i element to add font awsome class to make icons correct working */}
                                <Markdown options={{ overrides: { i: { props: { className: 'fa' } } } }} >
                                    {icon.social_icon.element}
                                </Markdown>
                            </Avatar>
                        );
                    })}
                </div>

                <div className="footer__copyright">
                    <img className="footer__copyright-slogan" src={sloganImage} alt="Wijzer in webontwikkeling" />
                    &copy; 2020 | Alle rechten voorbehouden |
                    {menu &&
                        <ul className="footer__menu">
                            {menu.items && menu.items.map((menuItem, index) => {
                                const { url, title, target, object_id, slug } = menuItem;

                                return (
                                    <li className="footer__menu-item" key={index}>
                                        <button onClick={(event) => handleButtonClick(event, { id: object_id, target, url, slug })}>
                                            {title}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </div>
            </div>
        </div>
    );
}

Footer.propTypes = {
    history: PropTypes.object.isRequired,
    socialIcons: PropTypes.array,
    backgroundImage: PropTypes.string,
    sloganImage: PropTypes.string,
};

export default withRouter(Footer);
