import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export function Error(props) {
    const { message, statusCode } = props;

    return (
        <div className="error-block container">
            <div className="error-block__content">
                {statusCode &&
                    <h1>
                        {statusCode}
                    </h1>
                }
                {message &&
                    <span>
                        {message}
                    </span>
                }
            </div>
        </div>
    );
}

Error.propTypes = {
    statusCode: PropTypes.string,
    message: PropTypes.string,
};

export default withRouter(Error);
