import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigate, getBackgroundAlignment, getMobileBackgroundAlignment } from '../utils/common.js';
// import QuoteBlock from './blocks/QuoteBlock';


export function Header(props) {
    const { buttons, title, subTitle, backgroundImage, history, changeTitle } = props;
    // Get backgroundImage from object or string url
    const { url, focuspositionx, focuspositiony, mobilefocuspositiony, mobilefocuspositionx } = backgroundImage && backgroundImage.url ? backgroundImage : { url: backgroundImage };
    const handleClickButton = (event, button) => {
        event.preventDefault();
        const { target, url } = button;
        navigate(history, { target, url });
    }

    React.useEffect(() => {
        changeTitle(title);
    }, [title, changeTitle]);

    return (
        <div className={`page-header${getBackgroundAlignment(focuspositionx, focuspositiony)}${getMobileBackgroundAlignment(mobilefocuspositionx, mobilefocuspositiony)}${url ? ' page-header--light' : ' page-header--dark'}`} style={{ backgroundImage: `${url ? `url(${url})` : ''}` }}>
            <div className="page-header__content">
                {title &&
                    <h1 className="page-header__title">
                        {title}
                    </h1>
                }
                {subTitle &&
                    <span className="page-header__subtitle">
                        {subTitle}
                    </span>
                }
                <div className="page-header__buttons">
                    {buttons && buttons.map((singleButton, index) => {
                        const button = singleButton.button;
                        if (button.title) {
                            return (
                                <button
                                    key={index}
                                    className="btn btn-primary uppercase"
                                    onClick={(event) => handleClickButton(event, button)}
                                >
                                    {button.title}
                                </button>
                            );
                        }
                        return false;
                    })
                    }
                </div>
            </div>
        </div>
    );
}

Header.propTypes = {
    buttons: PropTypes.array,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    backgroundImage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    history: PropTypes.object.isRequired,
    changeTitle: PropTypes.func.isRequired,
};

export default withRouter(Header);
