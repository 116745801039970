import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UseFetch from '../../hooks/useFetch';
import { navigate, shortenText } from '../../utils/common.js';
import parse from 'html-react-parser';
import Loader from '../Loader';

export function VacanciesBlock(props) {
    const { history, vacanciesToShow, selectedVacancyId, showNavigation, button, title } = props;
    const [vacancies] = UseFetch({ slug: 'wp/v2/vacatures/' });
    const [startIndex, setStartIndex] = React.useState(0);
    const numberOfVacancies = vacanciesToShow ? parseInt(vacanciesToShow) : 3;

    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;

        navigate(history, { target, url, slug });
    }

    const handleNavigateSlider = (event, next) => {
        event.preventDefault();
        if (next) {
            if ((startIndex - 1) >= 0) {
                setStartIndex(startIndex - 1);
            } else {
                setStartIndex(vacancies.length);
            }
        } else {
            if ((startIndex + 1) < vacancies.length) {
                setStartIndex(startIndex + 1);
            } else {
                setStartIndex(0);
            }
        }
    }

    const maxLengthVacanciesByIndex = vacancies && vacancies.filter((vacancy, index) => index >= startIndex && index < (startIndex + numberOfVacancies));
    const vacanciesToSelect = vacancies && (numberOfVacancies - maxLengthVacanciesByIndex.length);
    const maxVacancies = vacancies && vacancies.filter((vacancy, index) => index < vacanciesToSelect);

    const itemstoshow = () => {
        if (vacancies) {
            // merge duplicate vacancies -> only show uniqe
            return [...new Set([].concat(maxLengthVacanciesByIndex, maxVacancies))];
            // [
            //     ...maxLengthVacanciesByIndex,
            //     ...maxVacancies
            // ];
        } else {
            return null;
        }
    };
    const filteredSelected = vacancies && vacancies.filter((vacancy) => vacancy.id === selectedVacancyId);
    const vacanciesToRender = selectedVacancyId ? filteredSelected : itemstoshow();

    return (
        <div className="vacancy-block container">
            {title &&
                <h2 className="vacancy-block__title">
                    {title}
                </h2>
            }
            <div className="vacancy-block__vacancies">
                {vacanciesToRender && vacanciesToRender.length === 0 &&
                    <span className="vacancy-block__vacancies--error">
                        Er zijn op dit moment geen vacatures om te tonen!
                    </span>
                }

                {vacanciesToRender === null &&
                    <span className="vacancy-block__vacancies--loader">
                        <Loader message="Bezig met laden van de vacatures..." smallSize />
                    </span>
                }

                {showNavigation &&
                    <button
                        className="vacancy-block__navigation" onClick={(event) => handleNavigateSlider(event)}
                    >
                        {"<"}
                    </button>
                }

                {vacanciesToRender && vacanciesToRender.map((vacancy, index) => {
                    if (vacancy && (index < vacanciesToShow)) {
                        // create excerpt from content when no excerpt available
                        const excerptContent = shortenText((vacancy.excerpt.rendered || vacancy.content.rendered).replace(/<[^>]+>/g, ''), 200);

                        return (
                            <div
                                key={index}
                                className="vacancy-block__item"
                            >
                                <button
                                    className="btn-link"
                                    onClick={(event) => handleButtonClick(event, { url: `/vacatures/${vacancy.slug}` })}
                                >
                                    <h2 className="h4">
                                        {vacancy.title.rendered}
                                    </h2>
                                </button>
                                <div className="vacancy-block__item-content">
                                    {parse(excerptContent)}
                                </div>
                                <button
                                    className="btn uppercase btn-outline-primary"
                                    onClick={(event) => handleButtonClick(event, { url: `/vacatures/${vacancy.slug}` })}
                                >
                                    Lees meer
                                </button>
                            </div>
                        );
                    }
                    return false;
                }
                )}

                {showNavigation &&
                    <button
                        className="vacancy-block__navigation" onClick={(event) => handleNavigateSlider(event, true)}
                    >
                        {">"}
                    </button>
                }
            </div>

            {/* only render button when vacancies available */}
            {button && vacanciesToRender && vacanciesToRender.length !== 0 &&
                <div className="vacancy-block__actions">
                    <button
                        className="btn uppercase btn-outline-primary"
                        onClick={(event) => handleButtonClick(event, { url: button.url })}
                    >
                        {button.title}
                    </button>
                </div>
            }
        </div>
    );
}

VacanciesBlock.propTypes = {
    history: PropTypes.object.isRequired,
    vacanciesToShow: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    selectedVacancyId: PropTypes.number,
    showNavigation: PropTypes.bool,
    button: PropTypes.object,
};

export default withRouter(VacanciesBlock);
