import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UseFetch from '../hooks/useFetch';
import Markdown from 'markdown-to-jsx';
import { shortenText } from '../utils/common';
import parse from 'html-react-parser';
import { navigate } from '../utils/common.js';

export function Vacancies(props) {
    const { history, match } = props;
    const vacancySlug = history && history.location.pathname.replace(`${match.url}`, '');
    const [vacancies] = UseFetch({ slug: `wp/v2/vacatures/${vacancySlug ? `?slug=${vacancySlug}` : ''}` });
    const [categories] = UseFetch({ slug: 'wp/v2/soorten/' });

    const activeVacancy = !!vacancySlug ? (vacancies && vacancies[0]) : undefined;
    const [filter, setFilter] = React.useState();

    const handleOpenVacancy = (event, vacancySlug) => {
        event.preventDefault();
        history.push(`/vacatures/${vacancySlug || ''}`);
    }

    const handleButtonClick = (event, button) => {
        event.preventDefault();
        const { target, url } = button;

        navigate(history, { target, url });
    }

    const filteredVacancies = vacancies && filter ? vacancies.filter((vacancy) => vacancy.soorten.includes(filter.id)) : vacancies;

    return (
        <div className="vacancies-page container">
            {!activeVacancy && categories &&
                <div className="vacancies-page__categories">
                    {categories.map((category, index) => {
                        return (
                            <span className={`btn btn-secondary vacancies-page__categories-button uppercase ${filter && (filter.id === category.id) ? 'active' : ''}`} key={index} onClick={() => setFilter(category)}>
                                {category.name}
                            </span>);
                    })}
                    <span className={`btn btn-secondary vacancies-page__categories-button uppercase ${!filter ? 'active' : ''}`} onClick={() => setFilter()}>
                        Alles
                    </span>
                </div>
            }

            <div className="vacancies">
                {!activeVacancy && filteredVacancies && filteredVacancies.map((vacancy, index) => {
                    const excerptContent = shortenText((vacancy.excerpt.rendered || vacancy.content.rendered).replace(/<[^>]+>/g, ''), 200);

                    // all vacancies
                    return (
                        <div
                            key={index}
                            className="vacancies-item"
                        >
                            <button
                                className="btn-link"
                                onClick={(event) => handleOpenVacancy(event, vacancy.slug)}
                            >
                                <h2 className="h4">
                                    {vacancy.title.rendered}
                                </h2>
                            </button>

                            <div className="vacancies-item-content">
                                {parse(excerptContent)}
                            </div>

                            <div className="vacancies-actions">
                                <button
                                    className="btn btn-outline-primary uppercase"
                                    onClick={(event) => handleOpenVacancy(event, vacancy.slug)}
                                >
                                    Lees meer
                                </button>
                            </div>
                        </div>
                    );
                }
                )}
                {/* show error when no vacancies, or no vacancies in filtered category */}
                {!activeVacancy && filteredVacancies && filteredVacancies.length === 0 &&
                    <div className="vacancies-empty">
                        {`Er zijn op dit moment geen vacatures ${filter ? `in de categorie ${filter.name}` : ''} beschikbaar!`}
                    </div>
                }
            </div>

            {vacancySlug &&
                <div className="vacancies-page--single">
                    {activeVacancy &&
                        // one vacancy
                        <div className="vacancies-page--single-content">

                            <button onClick={(event) => handleOpenVacancy(event)}>
                                {"< Terug naar vacature overzicht"}
                            </button>

                            <br />

                            <h1>
                                {activeVacancy.title.rendered}
                            </h1>
                            <Markdown>
                                {activeVacancy.content.rendered}
                            </Markdown>

                            <button
                                className="btn btn-primary uppercase"
                                onClick={(event) => handleButtonClick(event, { url: '/contact' })}
                            >
                                Neem contact op
                                </button>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

Vacancies.propTypes = {
    vacancySlug: PropTypes.string,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(Vacancies);
