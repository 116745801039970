import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { navigate } from '../utils/common.js';
import Skeleton from '@material-ui/lab/Skeleton';

export function Navigation(props) {
    const { history, location, items, onOpenMenu } = props;

    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;

        if (url !== '#') {
            navigate(history, { target, url, slug });
            if (onOpenMenu) {
                onOpenMenu();
            }
        } else {
            openSubmenu(event);
        }
    }

    const openSubmenu = (event) => {
        event.currentTarget.classList.toggle('open');
    }

    const isLoading = !items;

    return (
        <ul className={`navigation ${isLoading ? 'is-skeleton-loading' : ''}`}>
            {isLoading &&
                <Skeleton variant="text" animation="wave" width="100%" />
            }
            {items && items.map((menuItem, index) => {
                const { url, title, target, child_items, object_id, slug } = menuItem;
                const current = slug ? `/${slug}` : url;

                // check if submenu has an active item
                const submenuActive = child_items && child_items.some((childItem) => {
                    const { url, slug } = childItem;
                    const current = slug ? `/${slug}` : url;
                    return location.pathname === current;
                });

                return (
                    <React.Fragment key={index} >
                        {/* set parent as active when currentpage is item or submenu item */}
                        <li className={`menu-item ${(location.pathname === current || submenuActive) ? 'active' : ''}`}>
                            <button className={`menu-item__link ${child_items ? 'menu-item__link-sub' : ''}`} onClick={(event) => handleButtonClick(event, { id: object_id, target, url, slug })}>
                                {title}
                            </button>

                            {child_items &&
                                <div className="menu-item__sub">
                                    {child_items.map((childItem, index) => {

                                        const { url, title, target, object_id, slug } = childItem;
                                        const current = slug ? `/${slug}` : url;
                                        return (
                                            <button key={index} className={`menu-item__sub-link ${location.pathname === current ? 'active' : ''}`} onClick={(event) => handleButtonClick(event, { id: object_id, target, url, slug })}>
                                                {title}
                                            </button>
                                        );
                                    })
                                    }
                                </div>
                            }
                        </li>
                    </React.Fragment>
                );
            })}
        </ul>
    );
}

Navigation.propTypes = {
    history: PropTypes.object.isRequired,
    onOpenMenu: PropTypes.func,
    location: PropTypes.object,
    items: PropTypes.array,
};

export default withRouter(Navigation);
