import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { navigate } from '../../utils/common.js';
import parse from 'html-react-parser';

export function IntroBlock(props) {
    const { button, content, history } = props;

    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;

        navigate(history, { target, url, slug });
    }

    return (
        <div className="intro-block container">
            {content && content !== '' &&
                <div className="intro-block__content">
                    {/* replace "p" tag with span */}
                    <Markdown options={{ overrides: { p: ({ children, ...props }) => <span>{children}</span> } }}>
                        {parse(content)}
                    </Markdown>
                </div>
            }
            {button &&
                <button
                    className="btn uppercase btn-primary"
                    onClick={(event) => handleButtonClick(event, { url: button.url })}
                >
                    {button.title}
                </button>
            }
        </div>
    );
}

IntroBlock.propTypes = {
    content: PropTypes.string.isRequired,
    button: PropTypes.object,
    history: PropTypes.object.isRequired,
};

export default withRouter(IntroBlock);
