import React from 'react';
import PropTypes from 'prop-types';
import UseFetch from '../../hooks/useFetch';
import Markdown from 'markdown-to-jsx';
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { navigate, shortenText, getBackgroundAlignment } from '../../utils/common.js';
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import Loader from '../Loader';

export function Posts(props) {
    const { postsToShow, history, match } = props;
    const [page, setPage] = React.useState(1);
    const [allPosts, setAllPosts] = React.useState([]);
    const postSlug = history && history.location.pathname.replace(`${match.url}`, '');
    const [eventListnerStarted, setEventlistnerStarted] = React.useState(false);
    const [pageQuery, setPageQuery] = React.useState(`per_page=10&page=1`);
    const [posts, headers] = UseFetch({ slug: `wp/v2/posts?${pageQuery}${postSlug ? `&slug=${postSlug}` : ''}` });
    const totalPages = headers && headers.get('X-Wp-Totalpages');
    const totalPosts = headers && headers.get('X-Wp-Total');
    const activePost = !!postSlug ? (posts && posts[0]) : undefined;

    const defaultItemsPerPage = 10;

    const handleButtonClick = (event, route) => {
        event.preventDefault();

        const { target, url, slug } = route;

        if (url) {
            // scroll back to top when open single post from overview page
            window.scrollTo(0, 0);
        }

        navigate(history, { target, url, slug });
    }

    const fetchMoreItems = () => {
        if (allPosts && (allPosts.length !== parseInt(totalPosts))) {
            setTimeout(() => {
                if ((page + 1 <= totalPages) && isFetching === true) {
                    setPage(page + 1);
                }
            }, 2000);
        }
    }

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems, eventListnerStarted);

    React.useEffect(() => {
        // this is to only fetch the items needed -> when itemsToShow is smaller then max items + new page only fetch the difference
        let perPage = defaultItemsPerPage;

        // if items + new page is bigger than max posts and isFetching -> calculate next items to fetch
        if (isFetching === true && ((allPosts.length + defaultItemsPerPage) > postsToShow)) {
            perPage = postsToShow % allPosts.length;
        }

        const itemsPP = postsToShow > perPage ? perPage : postsToShow;
        if (itemsPP !== undefined) {
            setPageQuery(`per_page=${itemsPP}&page=${page}`);
        }
    }, [page, isFetching, postsToShow, allPosts]);

    React.useEffect(() => {
        // add new fetched posts to total list of posts
        if (posts && posts.length && posts !== null) {
            if (allPosts.length !== (allPosts.length + posts.length)) {
                setAllPosts(oldPosts => ([...oldPosts, ...posts]));
                setIsFetching(false);
            }
        }
    }, [posts, setIsFetching]);

    React.useEffect(() => {
        // remove eventlistener when all posts are loaded
        if (allPosts && ((allPosts.length === parseInt(totalPosts)) || (allPosts.length >= postsToShow))) {
            setEventlistnerStarted(false);
        }
    }, [allPosts, postsToShow, totalPosts]);

    React.useEffect(() => {
        // enable eventlistener when in overview
        if ((!!activePost === false) && (allPosts.length < parseInt(totalPosts)) && (allPosts.length < postsToShow)) {
            setEventlistnerStarted(true);
        }
    }, [activePost, allPosts, postsToShow, totalPosts]);


    return (
        <div className="posts-block container">
            {/* all posts */}
            <div className="posts">
                {!activePost && allPosts && allPosts.map((post, index) => {
                    if (index < postsToShow) {
                        const { slug, acf } = post;
                        const imageUrl = acf && acf.nieuwsimage ? acf.nieuwsimage.url : '';

                        // whitelist variants so no wrong class can be rendered from wordpress
                        const whitelistVariants = [
                            'image_left',
                            'image_right',
                            'no-image',
                        ];

                        // see whitelist for variants
                        const variant = acf && acf.variant && whitelistVariants.includes(acf.variant) ? ` ${acf.variant}` : '';

                        // check if post has image
                        const hasImage = acf && acf.image_preview === 'big';

                        return (
                            <div
                                key={index}
                                className={`posts-item${hasImage ? ' posts-item--hasImage' : ''}`}
                            >
                                <div className={`posts-item-row${variant}`}>
                                    <div className="posts-item-content">
                                        <strong>
                                            {format(new Date(post.date), "EEEE dd LLLL yyyy", { locale: nl })}
                                        </strong>
                                        <h2 className="h4">
                                            <button
                                                className="posts-item-content__title btn-link"
                                                onClick={(event) => handleButtonClick(event, { url: `/nieuws/${slug}` })}
                                            >
                                                {parse(post.title.rendered)}
                                            </button>
                                        </h2>
                                        <div className="posts-item-content__text">
                                            {parse(shortenText(post.excerpt.rendered, 420))}
                                        </div>
                                    </div>
                                    {acf && acf.nieuwsimage && acf.image_preview === 'big' &&
                                        <div className={`posts-item-image${getBackgroundAlignment(acf.focuspositionx, acf.focuspositiony)}`} style={{ backgroundImage: `${imageUrl ? `url(${imageUrl})` : ''}` }} />
                                    }
                                </div>

                                <div className="posts-item-actions">
                                    <button
                                        className="btn btn-primary uppercase"
                                        onClick={(event) => handleButtonClick(event, { url: `/nieuws/${slug}` })}
                                    >
                                        Lees meer
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    return false;
                })
                }
            </div>

            <div className="posts-block--loading">
                {/* show loader when is fetching new posts */}
                {isFetching && posts !== null &&
                    <Loader message="Nieuwsberichten worden geladen..." smallSize />
                }

                {/* show loader when no posts */}
                {posts === null &&
                    <Loader message="Nieuwsberichten worden geladen..." smallSize />
                }
            </div>

            {postSlug &&
                <div className="posts-block--single container">
                    {activePost &&
                        (
                            <div className="posts-block--single-content">
                                <button
                                    className="btn-link"
                                    onClick={(event) => handleButtonClick(event, { url: '/nieuws' })}
                                >
                                    {"< Terug naar nieuws"}
                                </button>

                                {activePost.date &&
                                    <div className="posts-block--single-date">
                                        {format(new Date(activePost.date), "EEEE dd LLLL yyyy", { locale: nl })}
                                    </div>
                                }

                                {activePost.acf.nieuwsimage &&
                                    <img className="posts-block--single-image" alt={parse(activePost.title.rendered)} src={activePost.acf.nieuwsimage.url} />
                                }

                                <h1>
                                    <Markdown>
                                        {parse(activePost.title.rendered)}
                                    </Markdown>
                                </h1>

                                <div className="posts-block--single-text">
                                    <Markdown options={
                                        {
                                            // open all links in new tab
                                            overrides: {
                                                a: {
                                                    props: {
                                                        target: '_blank',
                                                    },
                                                }
                                            }
                                        }
                                    }>
                                        {activePost.content.rendered}
                                    </Markdown>
                                </div>
                            </div>
                        )
                    }
                    {!activePost &&
                        <Loader message="Nieuwsbericht wordt geladen..." fullSize />
                    }
                </div>
            }
        </div >
    );
}

Posts.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    postsToShow: PropTypes.number,
};

export default withRouter(Posts);
