import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const useInfiniteScroll = (callback, fetchMore) => {
    const [isFetching, setIsFetching] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = useCallback(() => {
        // if not on end of the page do nothing
        if ((window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) || isFetching) {
            return;
        }

        // if on end then start fetching
        setIsFetching(true);
    });

    useEffect(() => {
        // only activat eventlistner when fetchMore prop is set to true or not passed
        if (fetchMore === true || fetchMore === undefined) {
            window.addEventListener('scroll', handleScroll);
        }
        // remove eventListener on unmount or on end of page
        return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchMore, handleScroll]);

    useEffect(() => {
        if (!isFetching) {
            return;
        }
        // run callback (fetch more items) when isFetching
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    return [isFetching, setIsFetching];
};

useInfiniteScroll.propTypes = {
    callback: PropTypes.func.isRequired,
    fetchMore: PropTypes.bool.isRequired,
};

export default useInfiniteScroll;
