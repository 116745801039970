import React from 'react';
import PropTypes from 'prop-types';
import UseFetch from '../hooks/useFetch';
import Header from './PageHeader';
// import Blocks from './Blocks';
import Markdown from 'markdown-to-jsx';
import TwitterBlock from './blocks/TwitterBlock';
import QuoteBlock from './blocks/QuoteBlock';
import ContactBlock from './blocks/ContactBlock';
import VacanciesBlock from './blocks/VacanciesBlock';
import StatisticsBlock from './blocks/StatisticsBlock';
import IntroBlock from './blocks/IntroBlock';
import CardsBlock from './blocks/CardsBlock';
import PostsBlock from './blocks/PostsBlock';
import ContentBlock from './blocks/ContentBlock';
import Vacancies from './Vacancies';
import Error from './Error';
import ContentImageBlock from './blocks/ContentImageBlock';
import { withRouter } from 'react-router-dom';
import Loader from './Loader';
import parse from 'html-react-parser';

export function Pages(props) {
    const { slug, changeTitle, contactInformation } = props; // history
    const [pages] = UseFetch({ slug: `${!!slug ? `wp/v2/pages?slug=${slug}` : undefined}` });
    const [activePage, setActivePage] = React.useState(undefined);


    React.useEffect(() => {
        // if page then set active page
        if (pages && pages !== null && pages.length > 0) {
            const currentPage = !!slug ? (pages && pages[0]) : undefined;
            const pageHeaderObject = currentPage && currentPage.acf && currentPage.acf.header;
            const hasButtons = currentPage && currentPage.acf && currentPage.acf.header.buttons && currentPage.acf.header.buttons[0].button !== null;
            const serializedButtons = hasButtons ? Object.keys(currentPage.acf.header.buttons).map((key) => currentPage.acf.header.buttons[key]) : undefined;
            const { focuspositionx, focuspositiony, mobilefocuspositionx, mobilefocuspositiony, background_image } = pageHeaderObject || {};
            const pageHeaderBgImage = pageHeaderObject && background_image && focuspositionx && mobilefocuspositionx ? { url: background_image.url, focuspositionx, focuspositiony, mobilefocuspositionx, mobilefocuspositiony } : (background_image ? background_image.url : undefined);

            const newActivePage = {
                page: pages[0],
                title: (pageHeaderObject && currentPage.acf.header.title) ? currentPage.acf.header.title : currentPage.title.rendered,
                subTitle: (pageHeaderObject && currentPage.acf.header.subTitle) ? currentPage.acf.header.subTitle : undefined,
                backgroundImage: (pageHeaderObject && pageHeaderBgImage) ? pageHeaderBgImage : undefined,
                serializedButtons,
            };

            if (activePage === undefined || activePage === null || (activePage ? (newActivePage.page.id !== activePage.page.id) : undefined)) {
                setActivePage(newActivePage);
            }
        } else {
            // when no page set active to undefined -> show error
            if (pages && pages[0] === undefined && activePage !== undefined) {
                setActivePage(undefined);
            }

            // when fetching set active to null -> show loader
            if (pages === null && activePage !== null) {
                setActivePage(null);
            }
        }
    }, [pages, slug]); //slug

    const validateProp = (input) => {
        // because when wordpress sends empty prop then library makes it true,
        // e.g. title="" will be title:true in rendered component.
        //  that's not ok. it has to be title:undefined because there is no title
        return typeof input === 'boolean' ? undefined : input;
    }

    const StatisticsComponent = (props) => {
        const { backgroundcolor, content } = props;

        return <StatisticsBlock
            backgroundColor={validateProp(backgroundcolor)}
            content={validateProp(content)}
        />;
    };

    const QuoteComponent = (props) => {
        const { quote, quote_author, quote_image, focuspositionx, focuspositiony } = props;
        const imageUrl = typeof quote_image === 'string' ? quote_image : quote_image.url;
        const image = focuspositionx ? { url: imageUrl, focuspositionx, focuspositiony } : imageUrl;

        return <QuoteBlock
            primaryText={validateProp(quote)}
            secondaryText={validateProp(quote_author)}
            backgroundImage={image}
        />;
    };

    const IntroComponent = (props) => {
        const { buttonurl, buttontitle, buttontarget, content } = props;

        return <IntroBlock
            button={{ url: buttonurl, title: buttontitle, target: buttontarget }}
            content={content}
        />;
    };

    const CardsComponent = (props) => {
        const { title, content, cards, variant } = props;

        return <CardsBlock
            title={validateProp(title)}
            content={validateProp(content)}
            cards={validateProp(cards)}
            variant={validateProp(variant)}

        // content={content}
        />;
    };

    const TwitterComponent = (props) => {
        const { aantal, username } = props;
        const parsedNumber = parseInt(validateProp(aantal));

        return <TwitterBlock
            screenName={validateProp(username)}
            numberOfTweets={parsedNumber}
        />;
    };

    const NewsComponent = (props) => {
        const { aantal } = props;

        return <PostsBlock
            postsToShow={parseInt(validateProp(aantal))}
        />;
    };

    const VacanciesBlockComponent = (props) => {
        const { aantalvacatures, button, title } = props;

        const parsedButton = button && JSON.parse(`{ ${parse(button)} }`);
        return <VacanciesBlock
            vacanciesToShow={validateProp(aantalvacatures)}
            button={parsedButton}
            title={validateProp(title)}
        // showNavigation // bool
        // selectedVacancyId={171} // number - selected vacancy id to show
        />;
    };

    const ContactComponent = (props) => {
        const { children, googlemaps, title } = props;
        const googleMapsProps = googlemaps && googlemaps !== 'Array' && JSON.parse(`{ ${parse(googlemaps)} }`);

        return <ContactBlock
            googleMapsProps={googleMapsProps} // object with props from wordpress
            // markerLocations={[{ lat: 52.0436, lng: 5.5688 }]} // array with markers (can be multiple)
            title={validateProp(title)}
            text=""
            contactInfo={{
                street: contactInformation && contactInformation.address && contactInformation.address.street,
                number: contactInformation && contactInformation.address && contactInformation.address.number,
                postalCode: contactInformation && contactInformation.address && contactInformation.address.postcode,
                city: contactInformation && contactInformation.address && contactInformation.address.woonplaats,
                country: contactInformation && contactInformation.address && contactInformation.address.land,
                website: contactInformation && contactInformation.website,
                email: contactInformation && contactInformation.email,
                phone: contactInformation && contactInformation.telefoon,
            }}
            contactPageComponent={children}
        />;
    };

    const ContentImageComponent = (props) => {
        const { variant, text, title, image, buttons, width } = props;

        // add curly braces to parse the image correct
        // parse image from api to valid object
        const parsedImage = image && JSON.parse(parse(`{${image}}`));
        const parsedButtons = buttons && JSON.parse(parse(props.buttons));

        // default center content
        let inverse = undefined;
        switch (variant) {
            case 'text-left':
                // not inverse content, show text left
                inverse = false;
                break;
            case 'text-right':
                // inverse content, show text right
                inverse = true;
                break;
            default:
                // default center content
                inverse = undefined;
        };

        return <ContentImageBlock
            inverseImageAlignment={inverse} // when undefined show text in middle
            image={validateProp(parsedImage)}
            title={validateProp(title)}
            content={validateProp(text)}
            buttons={validateProp(parsedButtons)}
            size={validateProp(width)}
        />;
    };

    const ContentComponent = (props) => {
        const { alignment, text, title, width } = props;
        return <ContentBlock
            title={validateProp(title)}
            content={validateProp(text)}
            size={validateProp(width)}
            alignment={validateProp(alignment)}
        />;
    };

    const VacanciesComponent = (props) => {
        return <Vacancies />;
    };

    return (
        <React.Fragment>
            {/* render selected page */}
            {activePage && activePage.page !== null && activePage.page !== undefined &&
                // render only the first page when multiple pages with same slug
                <React.Fragment>
                    <Header
                        title={activePage.title}
                        subTitle={activePage.subTitle}
                        backgroundImage={activePage.backgroundImage}
                        buttons={activePage.serializedButtons} // [{ title: '', url: ''}]
                        changeTitle={changeTitle}
                    />

                    {/* Render page content and replace custom blocks with react components */}
                    <Markdown
                        children={activePage.page.content.rendered}
                        options={
                            {
                                overrides: {
                                    statistics: {
                                        component: StatisticsComponent,
                                    },
                                    quote: {
                                        component: QuoteComponent,
                                    },
                                    intro: {
                                        component: IntroComponent,
                                    },
                                    cardscontainer: {
                                        component: CardsComponent,
                                    },
                                    twitter: {
                                        component: TwitterComponent,
                                    },
                                    news: {
                                        component: NewsComponent,
                                    },
                                    vacatures: {
                                        component: VacanciesBlockComponent,
                                    },
                                    contact: {
                                        component: ContactComponent,
                                    },
                                    textimage: {
                                        component: ContentImageComponent,
                                    },
                                    text: {
                                        component: ContentComponent,
                                    },
                                    allvacancies: {
                                        component: VacanciesComponent,
                                    }
                                }
                            }
                        }
                    />
                </React.Fragment>
            }

            {/* no page found -> 404 */}
            {!activePage && pages && pages.length === 0 && slug &&
                <Error
                    statusCode="404"
                    message="Deze pagina is niet gevonden"
                />
            }

            {/* loader for initial page load */}
            {activePage === null && !pages &&
                <Loader message="Bezig met laden van de pagina..." fullSize />
            }
        </React.Fragment >
    );
}

Pages.propTypes = {
    slug: PropTypes.string.isRequired,
    changeTitle: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    contactInfo: PropTypes.object,
};

export default withRouter(Pages);
