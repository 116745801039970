import React from 'react';
import PropTypes from 'prop-types';

function Loader(props) {
    const { message, fullSize, smallSize, delay } = props;
    const [showText, setShowText] = React.useState(false);

    React.useEffect(() => {
        const realDelay = delay || "400";
        const timeout = window.setTimeout(() => {
            setShowText(true);
        }, realDelay); // delay in ms

        return () => {
            window.clearTimeout(timeout);
        };
    }, [delay]);

    return (
        <div className={`loader-container${fullSize ? ' full' : ''}${smallSize ? ' small' : ''}`}>
            <div className="loader-content">
                <div className="loader">
                    {message && showText && (
                        <span>
                            {message}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

Loader.propTypes = {
    message: PropTypes.string,
    fullSize: PropTypes.bool,
    smallSize: PropTypes.bool,
    delay: PropTypes.string,
};

export default Loader;
