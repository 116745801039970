export function navigate(history, route) {
    const { target, url, slug } = route;

    if (target === '_blank' || (!slug && url.startsWith('http'))) {
        // open custom url in new tab
        window.open(url, '_blank');
    } else {
        if (slug) {
            history.push(`/${slug}`);
        } else {
            history.push(url);
        }
    }
}

// shorten text to maxLength -> break on full word
export function shortenText(text, maxLength) {
    return text && text.length > maxLength ? text.substring(0, maxLength).replace(/\s+\S*$/, "") : text;
}

// get correct background alignment class
export function getBackgroundAlignment(focusX, focusY) {
    return focusX ? ` background--${focusX}${typeof focusY === 'string' || typeof focusY === 'number' ? `-${focusY}` : ''}` : '';
};

// get correct mobile background alignment class
export function getMobileBackgroundAlignment(mobileFocusX, mobileFocusY) {
    return mobileFocusX ? ` background-mobile--${mobileFocusX}${typeof mobileFocusY === 'string' || typeof mobileFocusY === 'number' ? `-${mobileFocusY}` : ''}` : '';
};
