import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigate } from '../utils/common.js';
import Navigation from './Navigation';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import UseFetch from '../hooks/useFetch';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

export function Header(props) {
    const { history, logo } = props;
    const [showMobile, toggleShowMobile] = React.useState(false);
    const [menu] = UseFetch({ slug: 'menus/v1/menus/main' });

    //Todo: maybe use redux to save menu items

    const handleButtonClick = (event, button) => {
        event.preventDefault();
        const { target, url } = button;

        navigate(history, { target, url });
    }

    const handleOpenMenu = () => {
        toggleShowMobile(!showMobile);
    }

    const mobileMenu = () => {
        return (
            <React.Fragment>
                {logo &&
                    <button
                        data-test-hook="mobile-logo"
                        className="mobile-logo"
                        onClick={(event) => handleButtonClick(event, { url: '/' })}
                    >
                        <img src={logo} alt="Alteza logo" />
                    </button>
                }
                <span className="mobile-toggle" data-test-hook="mobile-toggle" onClick={handleOpenMenu}>
                    {!showMobile && <MenuIcon fontSize="large" />}
                    {showMobile && <MenuOpenIcon fontSize="large" />}
                </span>
            </React.Fragment>);
    }

    return (
        <React.Fragment>
            <div className="desktop" >
                <div className="header">
                    <nav className="menu">
                        {logo &&
                            <button
                                className="header__logo"
                                data-test-hook="web-logo"
                                onClick={(event) => handleButtonClick(event, { url: '/' })}
                            >
                                <img className="header__logo-image" src={logo} alt="Alteza logo" />
                            </button>
                        }
                        <Navigation items={menu && menu.items} />
                    </nav>
                </div>
            </div >

            <div className="mobile">
                <div data-test-hook="mobile-menu" className={`mobile-menu ${showMobile ? 'mobile-menu--open' : ''}`}>
                    {mobileMenu()}
                </div>
                <SwipeableDrawer
                    anchor="bottom"
                    open={showMobile}
                    onClose={() => toggleShowMobile(!showMobile)}
                    onOpen={() => toggleShowMobile(!showMobile)}
                >
                    {mobileMenu()}
                    <nav className="menu menu__mobile">
                        <Navigation items={menu && menu.items} onOpenMenu={() => handleOpenMenu()} />
                    </nav>
                </SwipeableDrawer>
            </div>
        </React.Fragment>
    );
}

Header.propTypes = {
    logo: PropTypes.string,
    history: PropTypes.object.isRequired,
};

export default withRouter(Header);
