import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UseFetch from '../hooks/useFetch';
import Markdown from 'markdown-to-jsx';
import { renderToString } from 'react-dom/server';

export function ContactForm(props) {
    const { contactPageComponent } = props;
    const [contactPage] = UseFetch({ slug: 'wp/v2/pages/1301' });
    const [formErrors, setformErrors] = React.useState({});
    const [message, setMessage] = React.useState({});
    const [siteInfo] = UseFetch({ slug: '' });

    const [formData, setFormData] = React.useState({});

    React.useEffect(() => {
        window.scrollTo(0, 0);
    },[contactPage]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        // save form data
        let allValues = {};
        for (var value of data.entries()) {
            allValues = { ...allValues, [value[0]]: value[1] };
        }
        setFormData(allValues);

        // post form data to wp
        fetch(`${siteInfo ? siteInfo.url : process.env.REACT_APP_API_ENDPOINT}contact-form-7/v1/contact-forms/461/feedback`, { // todo: dynamic id from  contactPage
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            body: new URLSearchParams(data).toString(),
        })
            .then((response) => (response.json()))
            .then((response) => {
                const { status, message } = response;
                if (status === 'validation_failed') {
                    const { invalid_fields } = response;

                    let errors = {};
                    invalid_fields.map((field) => {
                        const { into, message } = field;
                        const name = into.split('pan.wpcf7-form-control-wrap.'); // remove name from string
                        return errors = {
                            ...errors,
                            [name[1]]: message
                        }
                    });

                    setformErrors({
                        // ...formErrors,
                        ...errors
                    });
                }
                setMessage({ status, message });
            });
    };

    // replacable for form - add onSubmit funtion
    const form = ({ children }) => {
        return (
            <form encType="multipart/form-data" method="post" onSubmit={(event) => handleSubmit(event)} className="wpcf7-form init" >
                {children}
            </form>
        );
    };

    // replacable for input - remove value
    const input = ({ children, value, name, ...props }) => {
        return (
            <React.Fragment>
                <input name={name} autoFocus defaultValue={formData && formData[name] ? formData[name] : ''} {...props} />
                {formErrors && formErrors[name] &&
                    <span className="input-error"> {formErrors[name]} </span>
                }
            </React.Fragment>
        );
    };

    // replacable for textarea - remove value
    const textArea = ({ children, value, name, ...props }) => {
        return (<textarea name={name} defaultValue={formData && formData[name] ? formData[name] : ''} {...props} />);
    };

    return (
        <React.Fragment>
            {contactPage &&
                <div className="contact">
                    {message && message.status !== 'mail_sent' &&
                        <Markdown options={{ overrides: { form: { component: form }, input: { component: input }, textarea: { component: textArea } } }}>
                            {renderToString(contactPageComponent)}
                        </Markdown>
                    }
                    {message && Object.keys(message).length > 0 &&
                        <span className={`contact${message.status === 'validation_failed' ? '-error' : '-correct'}`}>
                            {message.message}
                        </span>
                    }
                </div>
            }
        </React.Fragment>
    );
}

ContactForm.propTypes = {
    history: PropTypes.object.isRequired,
    contactPageComponent: PropTypes.node,
};

export default withRouter(ContactForm);
