import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Markdown from 'markdown-to-jsx';
// import parse from 'html-react-parser';
import { getBackgroundAlignment } from '../../utils/common';

export function QuoteBlock(props) {
    const { primaryText, secondaryText, backgroundImage } = props;

    // Get backgroundImage from object or string url
    const { url, focuspositionx, focuspositiony } = backgroundImage && backgroundImage.url ? backgroundImage : { url: backgroundImage };

    return (
        <div className={`quote-block${getBackgroundAlignment(focuspositionx, focuspositiony)} ${url ? 'quote-block--light' : 'quote-block--dark'}`} style={{ backgroundImage: `${url ? `url(${url})` : ''}` }}>
            <div className="quote-block__content">
                {primaryText &&
                    <h3 className="quote-block__content-quote">
                        {primaryText}
                    </h3>
                }
                {secondaryText &&
                    <span className="quote-block__content-author">
                        {secondaryText}
                    </span>
                }
            </div>
        </div>
    );
}

QuoteBlock.propTypes = {
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    backgroundImage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    history: PropTypes.object.isRequired,
};

export default withRouter(QuoteBlock);
