import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import parse from 'html-react-parser';

export function StatisticsBlock(props) {
    const { backgroundColor, content } = props;

    return (
        <div className="statistics-block">
            {content &&
                <div className={`statistics-block__content statistics-block__content--${backgroundColor ? backgroundColor : 'default'}`}>
                    {/* replace "p" tag with span */}
                    <Markdown options={{ overrides: { p: ({ children, ...props }) => <span>{children}</span> } }}>
                        {parse(content)}
                    </Markdown>
                </div>
            }
        </div>
    );
}

StatisticsBlock.propTypes = {
    content: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default StatisticsBlock;
