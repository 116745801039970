import React, { useState } from 'react';
import Pages from './components/Pages';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import UseFetch from './hooks/useFetch';
import Loader from './components/Loader';

// import { useDispatch, useSelector } from "react-redux";
// import { setGenericInfo } from './actions';

function App(props) {
    const [optionsData] = UseFetch({ slug: 'acf/v3/options/options' });
    const genericInfo = optionsData && optionsData.acf;
    const [contactInformation, setContactInformation] = useState();
    // const contactInformation = {};
    // const generic = useSelector(state => state.generic);
    // const dispatch = useDispatch();

    // save generic info to redux if needed
    // React.useEffect(() => {
    //     console.log('data is fetched', genericInfo);
    //     dispatch(setGenericInfo(genericInfo));
    // }, [genericInfo]);


    // change title
    const changeTitle = (title) => {
        // todo: fix this!!!!!!!
        if (title) {
            const newTitle = `${title ? `${title} |` : ''} Alteza`; // todo: make this " | Alteza " dynamic, get from api result (name / description)
            document.title = newTitle;
        }
    }

    React.useEffect(() => {
        // set favicon from wordpress
        if (genericInfo !== undefined && genericInfo !== null) {
            if (genericInfo.favicon) {
                let link = document.querySelector('link[rel="shortcut icon"]') ||
                    document.querySelector('link[rel="icon"]');

                if (!link) {
                    link = document.createElement('link');
                    link.id = 'favicon';
                    link.rel = 'shortcut icon';
                    document.head.appendChild(link);
                }
                link.href = genericInfo.favicon.url;
            }

            // save contact information
            const serializedContactInfo = {
                email: genericInfo.emailadres || '',
                telefoon: genericInfo.telefoonnummer || '',
                website: genericInfo.website_url || '',
                address: genericInfo.adres || {},
            };

            setContactInformation(serializedContactInfo);
        }
    }, [genericInfo]);

    const logoImage = genericInfo && (typeof genericInfo.logo === 'string' ? genericInfo.logo : genericInfo.logo.url);
    const footerBackgroundImage = genericInfo && (typeof genericInfo.footer_background_image === 'string' ? genericInfo.footer_background_image : genericInfo.footer_background_image.url);
    const footerSloganImage = genericInfo && (typeof genericInfo.slogan === 'string' ? genericInfo.slogan : genericInfo.slogan.url);

    return (
        <React.Fragment>
            {/* loading state */}
            {genericInfo === null &&
                <Loader message="Alteza.nl wordt voor u opgestart!" delay="1000" />
            }
            {/* loaded state */}
            {genericInfo !== undefined && genericInfo !== null && contactInformation !== undefined && contactInformation !== null &&
                <Router>
                    <Header
                        logo={logoImage}
                    />
                    <div className="content">
                        <Switch>
                            {/* route for admin panel */}
                            <Route
                                path="/beheer"
                                component={() => {
                                    global.window && (global.window.location = process.env.REACT_APP_API_ENDPOINT.replace('wp-json', 'wp-admin'));
                                    return null;
                                }}
                            />
                            {/* other routes */}
                            <Route
                                path="/:slug?"
                                render={({ match }) => {
                                    const { slug } = match.params;
                                    return (
                                        <Pages
                                            slug={slug || "homepage"}
                                            changeTitle={changeTitle}
                                            contactInformation={contactInformation}
                                        />
                                    );
                                }}>
                            </Route>
                        </Switch>
                    </div>
                    <Footer
                        socialIcons={genericInfo && genericInfo.social_media_icons}
                        sloganImage={footerSloganImage}
                        backgroundImage={footerBackgroundImage}
                    />
                </Router>
            }

            {/* error state e.g. when no connection to wordpress */}
            {genericInfo === undefined &&
                <div className="error-container">
                    <span className="error-container_text">
                        Er is een fout opgetreden, probeer het later opnieuw!
                    </span>
                </div>
            }
        </React.Fragment>
    );
};

export default App;
