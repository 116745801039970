import { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';

export function UseFetch({ slug, url: customUrl }) {
    const url = customUrl || process.env.REACT_APP_API_ENDPOINT;
    const [data, setData] = useState([null, null,]);

    const sanitizeData = (dataToSanitize) => {
        // if is array then sanitize the html content
        if (dataToSanitize.length) {
            return dataToSanitize.map((item, index) => {
                // sanitize html content and excerpt for pages and custom types
                return {
                    ...item,
                    content: {
                        rendered: item.content && sanitizeHtml(item.content.rendered, {
                            allowedTags: false,
                            allowedAttributes: false,
                        })
                    },
                    excerpt: {
                        rendered: (item.excerpt && item.excerpt.rendered) || undefined,
                    },
                }
            });
        } else {
            // else return normal data
            return dataToSanitize;
        }
    };

    useEffect(() => {
        async function loadData() {
            try {
                const urlToFetch = `${url}${slug}`;
                const response = await fetch(urlToFetch);

                if (!response.ok) {
                    console.error('Error fetching data for', slug);
                    setData(data => [undefined, data[1]]);
                    return;
                }

                // sanitize html from api to jsx
                const result = await response.json();
                if (result) {
                    // set data and headers
                    setData([sanitizeData(result), response.headers]);
                }
            }
            catch (error) {
                console.error('Error fetching data for', slug, error);
                setData(data => [undefined, data[1]]);
            }
        }

        try {
            if (!!slug) {
                setData(data => [null, data[1]]);
                loadData();
            } else {
                setData(data => [null, data[1]]);
            }
        } catch (error) {
            console.error('Error fetching data for', slug, error);
            setData(data => [undefined, data[1]]);
        }
    }, [url, slug]);
    return data;
}

UseFetch.propTypes = {
    slug: PropTypes.string.isRequired,
};

export default UseFetch;
