import React from 'react'
import PropTypes from 'prop-types';
import { Gmaps, Marker } from 'react-gmaps';

export function Map(props) {
    const { markers, zoom, size } = props;
    const [center, setCenter] = React.useState({ lat: 0, lng: 0 });
    const [containerSize, setContainerSize] = React.useState({ width: '100%', height: '400px' });

    // set size of map
    React.useEffect(() => {
        // only set size when defined
        if (size && size.width && (containerSize.width !== size.width || containerSize.height !== size.height)) {
            setContainerSize(size);
        }
    }, [size, containerSize]);

    // center map
    React.useEffect(() => {
        // only center when one marker
        if (markers && markers.length === 1) {
            setCenter(markers[0]);
        }
    }, [markers]);

    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        // fit bounds only when multiple markers
        if (markers && markers.length > 1) {
            markers.map((marker, index) => {
                return bounds.extend(marker);
            });
            map.fitBounds(bounds);
        }
    }, [markers]);

    // todo: think about a way to store the apikey not in frontend

    const params = { v: '3.exp', key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY };

    return (
        <Gmaps
            width={containerSize.width}
            height={containerSize.height}
            lat={center.lat}
            lng={center.lng}
            zoom={zoom || 16}
            loadingMessage={'Bezig met laden van de kaart...'}
            params={params}
            onMapCreated={onLoad}
        >
            {markers && markers.map((marker, index) => {
                return (
                    <Marker
                        key={index}
                        lat={marker.lat}
                        lng={marker.lng}
                        icon="/maps_marker.png"
                    />
                );
            })}
        </Gmaps>
    )
}

Map.propTypes = {
    markers: PropTypes.array,
    zoom: PropTypes.number,
    size: PropTypes.object,
};

export default React.memo(Map);
