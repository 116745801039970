import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navigate } from '../../utils/common.js';
import getTweets from '../../hooks/getTweets';
import Markdown from 'markdown-to-jsx';
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import Avatar from '@material-ui/core/Avatar';

export function TwitterBlock(props) {
    // todo: auto slide tweets
    const { history, numberOfTweets, screenName } = props;

    const tweets = getTweets({ numberOfTweets, screenName });
    const [activeTweetIndex, setActiveTweetIndex] = React.useState(0);

    const activeTweet = tweets && tweets[activeTweetIndex];

    const handleButtonClick = (event, route) => {
        event.preventDefault();
        const { target, url, slug } = route;
        navigate(history, { target, url, slug });
    }

    const navigateTweets = (next) => {
        if (next === true) {
            setActiveTweetIndex(activeTweetIndex + 1);
        } else {
            setActiveTweetIndex(activeTweetIndex - 1);
        }
    }

    return (
        <React.Fragment>
            {activeTweet &&
                <div className="twitter-block container">
                    <Avatar className="twitter-block__icon">
                        <i className="fab fa-twitter fa" aria-hidden="true"></i>
                    </Avatar>

                    <span className="twitter-block__info">
                        <strong>
                            {activeTweet.user.name}
                        </strong>
                        <button
                            className="btn-link"
                            onClick={(event) => handleButtonClick(event, { url: `https://www.twitter.com/${activeTweet.user.screen_name}` })}
                        >
                            @{activeTweet.user.name}
                        </button>
                        <div className="twitter-block__info-date">
                            - {format(new Date(activeTweet.created_at), "EEEE dd LLLL yyyy", { locale: nl })}
                        </div>
                    </span>

                    <Markdown>
                        {activeTweet.text}
                    </Markdown>

                    {/* If more than 1 tweet add navigation */}
                    {numberOfTweets && numberOfTweets > 1 &&
                        <React.Fragment>
                            {(activeTweetIndex - 1) >= 0 &&
                                <span
                                    className="twitter-block__navigation twitter-block__navigation--prev"
                                    onClick={() => navigateTweets(false)}>
                                    {"<"}
                                </span>
                            }
                            {(activeTweetIndex + 1) < tweets.length &&
                                <span
                                    className="twitter-block__navigation twitter-block__navigation--next"
                                    onClick={() => navigateTweets(true)}>
                                    {">"}
                                </span>
                            }
                        </React.Fragment>
                    }
                </div>
            }
        </React.Fragment>
    );
}

TwitterBlock.propTypes = {
    history: PropTypes.object.isRequired,
    numberOfTweets: PropTypes.number,
    screenName: PropTypes.string,
};

export default withRouter(TwitterBlock);
